import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import AudioComponent from '../../../components/AudioComponent/AudioComponent';
import { Button } from '../../../Molecules/Button/Button';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { getBreadCrumb, removeHtmlTag } from '../../../Utils/Utils';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers';
import Layout from '../../../Layout';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import { TitlePage } from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import VisuelTextOverlappedBlock from '../../../components/ContentType/VisuelTextOverlappedBlock/VisuelTextOverlappedBlock';
import useMedias from '../../../hooks/useMedias';

import './styles.scss';

const classNames = require('classnames');

const AboutUsPage = ({ data, pageContext }) => {
  const intl = useIntl();
  const { processUrl, getImage } = useMedias();

  const { locale } = pageContext;
  const allBlocs = data.allBlocs?.edges[0]?.node;
  const innerNavArr = [];
  const imageArray = data.allImages?.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, allBlocs.path?.alias);
  const metaTags = allBlocs.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });

  const [allText, setAllText] = useState('');

  let globalText = '';

  useEffect(() => {
    setAllText(removeHtmlTag(`${allBlocs.title}${globalText}`));
  }, []);

  allBlocs?.relationships?.field_blocs.forEach((block, i) => {
    if (i > 0) {
      innerNavArr.push({
        title: block.field_title?.processed,
        section: 'scroll_to' + '_' + i,
      });
    }
  });

  const getSectionName = (title) => {
    let secName = '';
    innerNavArr.forEach((item) => {
      if (item.title === title) secName = item.section;
    });
    return secName;
  };

  const linkButtonHandler = (e) => {
    window.open(e.currentTarget.getAttribute('data-url'), '_self');
  };

  return (
    <Layout>
      <Seo
        title={allBlocs?.field_metatag?.title ? allBlocs?.field_metatag?.title : metaTitle}
        description={allBlocs?.field_metatag?.description ? allBlocs?.field_metatag?.description : metaDesc}
      />
      <div
        className={classNames(
          'page_template',
          'about_us_page',
          'zero_bottom_padding'
        )}
      >
        <ScrollPageNav data={innerNavArr} />

        {allBlocs?.relationships?.field_blocs.map((block, i) => {
          globalText += `${block.field_title?.processed != undefined &&
            block.field_title?.processed != null
            ? block.field_title?.processed
            : ''
            }.${block.field_subtitle?.processed != undefined &&
              block.field_subtitle?.processed != null
              ? block.field_subtitle?.processed
              : ''
            }.${block.body?.processed != undefined && block.body?.processed != null
              ? block.body?.processed
              : ''
            }`;
          switch (block.__typename) {
            case 'block_content__banner':
              return (
                <div key={i}>
                  {block.relationships ? (
                    <PageBanner
                      visuel={getImage(
                        imageArray,
                        block.relationships?.field_image?.drupal_internal__mid
                      )}
                      alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                      scroll={true}
                    >
                      <div className="wrapper_page">
                        <Breadcrumb
                          page_banner={true}
                          data={{
                            parentPage: {
                              title: breadCrumb?.parent?.name,
                              url: breadCrumb?.parent?.link,
                            },
                            currentPage: {
                              title: breadCrumb?.current?.name,
                              url: breadCrumb?.current?.link,
                            },
                            locale: allBlocs.langcode,
                          }}
                        />

                        <div
                          className={
                            /*allText && allBlocs.field_version_audio && allText.length > 30 &&*/ 'title_audio'
                          }
                        >
                          {' '}
                          {/* !!!!!! if audio add title_autio if not put nothing */}
                          <TitlePage
                            color="color_white"
                            title={allBlocs.title}
                            description={block.body?.processed}
                          />
                          {
                            /*allText && allBlocs.field_version_audio && allText.length > 30 &&*/
                            <AudioComponent
                              text={intl.formatMessage({
                                id: 'detailsActivities.button.listen.label',
                              })}
                              textToRead={allText}
                              color="color_white"
                            />
                          }
                        </div>
                      </div>
                    </PageBanner>
                  ) : null}
                </div>
              );
            case 'block_content__block_notre_mission':
              return (
                <IntroPage
                  customClass={getSectionName(block.field_title?.processed)}
                  key={i}
                  title={block?.field_title?.processed}
                  subtitle={block?.field_subtitle?.processed?.replace(
                    /<p[^>]*>/g,
                    ''
                  )}
                  visuel={getImage(
                    imageArray,
                    block.relationships?.field_image?.drupal_internal__mid
                  )}
                  alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                  text={block?.body?.processed}
                  size="border"
                />
              );


            case 'block_content__block_notre_ambition':
              return (
                <section
                  className={classNames(
                    'section_content',
                    'our_ambition',
                    getSectionName(block.field_title?.processed)
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={i > 2 ? null : 'left'}
                    bottomInfinite={true}
                    color="catskill_white"
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block?.field_title?.processed}
                        description={block?.field_subtitle?.processed?.replace(
                          /<p[^>]*>/g,
                          ''
                        )}
                        type="arrow"
                      />
                      <TextVisuelTwoCol
                        orderInverse={false}
                        extremeLeft={true}
                        visuel={getImage(
                          imageArray,
                          block.relationships?.field_image?.drupal_internal__mid
                        )}
                        alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                        text={block?.body?.processed}
                      />
                    </div>
                  </ShapeBackground>
                </section>
              );
            case 'block_content__block_notre_raison':
              return (
                <section
                  className={classNames(
                    'section_content',
                    getSectionName(block.field_title?.processed)
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={null}
                    bottomInfinite={true}
                    color="catskill_white"
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block?.field_title?.processed}
                        description={block?.field_subtitle?.processed?.replace(
                          /<p[^>]*>/g,
                          ''
                        )}
                        type="arrow"
                      />
                      <TextVisuelTwoCol
                        orderInverse={true}
                        extremeRight={true}
                        //alignLeft={true}
                        visuel={getImage(
                          imageArray,
                          block.relationships?.field_image?.drupal_internal__mid
                        )}
                        alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                        text={block?.body?.processed}
                      />
                    </div>
                  </ShapeBackground>
                </section>
              );



            case 'block_content__block_nos_valeurs':
              return (
                <section
                  className={classNames(
                    'section_content our_values',
                    getSectionName(block.field_title?.processed)
                  )}
                  key={i}
                >
                  <ShapeBackground top="left" bottom="left" color="dark_bleu">
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block.field_title?.processed}
                        type="arrow"
                        h2color="white"
                      />
                    </div>
                    <VisuelTextOverlappedBlock
                      extremeLeft={true}
                      visuel={getImage(
                        imageArray,
                        block.relationships?.field_image?.drupal_internal__mid
                      )}
                      alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                      bgColor="color_bleu_sky"
                      text={block.body?.processed}
                    />
                    <div className="wrapper_page_xs">
                      {block.relationships.field_values.map((textBlock, j) => {
                        globalText += `${textBlock.field_bloc_text_1?.processed != undefined &&
                          textBlock.field_bloc_text_1?.processed != null
                          ? textBlock.field_bloc_text_1?.processed
                          : ''
                          }.${textBlock.field_bloc_text_2?.processed != undefined &&
                            textBlock.field_bloc_text_2?.processed != null
                            ? textBlock.field_bloc_text_2?.processed
                            : ''
                          }`;
                        return (
                          <div
                            className={classNames(
                              'details_values_container',
                              block.relationships?.field_values?.length - 1 == j
                                ? 'last'
                                : ''
                            )}
                            key={j}
                          >
                            <div className="details_values">
                              <div
                                className="details"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    textBlock.field_bloc_text_1?.processed,
                                }}
                              ></div>
                              <div className="values">
                                <div
                                  className="txt"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      textBlock.field_bloc_text_2?.processed,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {block.relationships?.field_image_2
                      ?.drupal_internal__mid && (
                        <div className="bottom_visuel">
                          <div className="visuel">
                            <img
                              src={
                                getImage(
                                  imageArray,
                                  block.relationships?.field_image_2
                                    ?.drupal_internal__mid
                                )?.uri?.url
                              }
                              alt={block.relationships?.field_image_2?.field_media_image?.alt || ''}
                              style={{ height: '100%', objectFit: 'cover' }}
                            />
                          </div>
                        </div>
                      )}
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_notre_origine':
              globalText += `${block.field_bloc_text_1[0]?.processed != undefined &&
                block.field_bloc_text_1[0]?.processed != null
                ? block.field_bloc_text_1[0]?.processed
                : ''
                }.${block.field_bloc_text_2?.processed != undefined &&
                  block.field_bloc_text_2?.processed != null
                  ? block.field_bloc_text_2?.processed
                  : ''
                }`;
              return (
                <section
                  className={classNames(
                    'section_content',
                    'our_origin',
                    getSectionName(block.field_title?.processed)
                  )}
                  key={i}
                >
                  <ShapeBackground bottom="right" color="catskill_white">
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block.field_title?.processed}
                        description={block.field_subtitle?.processed?.replace(
                          /<p[^>]*>/g,
                          ''
                        )}
                        type="arrow"
                      />
                      <div className="two_col_text_cta">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: block.field_bloc_text_1[0]?.processed,
                          }}
                        />
                        <div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: block.field_bloc_text_2?.processed,
                            }}
                          />
                          <Button
                            label={block.field_link?.title}
                            primary={true}
                            data-url={block.field_link?.url}
                            onClick={linkButtonHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_chiffres_clefs':
              // eslint-disable-next-line no-case-declarations
              const dataArr = [];
              block.relationships?.field_values?.forEach((item) => {
                dataArr.push({
                  number: item.field_chiffre,
                  title: item.field_legend?.processed,
                });
              });
              return (
                <KeyNumbers
                  key={i}
                  title={block.field_title?.processed}
                  subtitle={block.body?.processed?.replace(/<p[^>]*>/g, '')}
                  sectionName={getSectionName(block.field_title?.processed)}
                  textColor="white"
                  backgroundImg={getImage(
                    imageArray,
                    block.relationships?.field_image?.drupal_internal__mid
                  )}
                  datas={dataArr}
                  locale={locale}
                />
              );

            default:
              break;
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query aboutUsTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    allBlocs: allNodePageQuiSommesNous(
      filter: {
        langcode: { eq: $locale }
        path: { alias: { eq: $slug } }
        status: { eq: true }
      }
    ) {
      edges {
        node {
          drupal_internal__nid
          langcode
          path {
            alias
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          title
          status
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__banner {
                  id
                  langcode
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                  body {
                    processed
                  }
                }
                ... on block_content__block_notre_mission {
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_notre_ambition {
                  id
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_notre_raison {
                  id
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_nos_valeurs {
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_values {
                      field_bloc_text_1 {
                        processed
                      }
                      field_bloc_text_2 {
                        processed
                      }
                    }
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_image_2 {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_notre_origine {
                  id
                  field_bloc_text_1 {
                    processed
                  }
                  field_bloc_text_2 {
                    processed
                  }
                  field_link {
                    title
                    url
                    uri
                  }
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                }
                ... on block_content__block_chiffres_clefs {
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_values {
                      field_legend {
                        processed
                      }
                      field_chiffre
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel        
              }
            }
          }
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

AboutUsPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default AboutUsPage;
